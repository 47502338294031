<template>
  <v-sheet>
    <v-container>
      <v-row justify="center">
        <v-col cols="6">
          <h4>Send notification to a group</h4>
          <v-form @submit.prevent="sendNotification">
            <v-row align="center">
              <v-col cols="12">
                <v-select
                    v-model="channel"
                    :items="[{name: 'Notification', value: 'notification'},{name: 'SMS', value: 'sms'}]"
                    item-value="value"
                    item-text="name"
                    label="Choose channel"
                />
              </v-col>
              <v-col
                  class="d-flex"
                  cols="8"
              >
                <v-select
                    multiple
                    v-model="selectedCourses"
                    :items="courses"
                    item-value="id"
                    item-text="title"
                    label="Choose course"
                    chips
                >
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-switch v-model="all" label="Send to all"></v-switch>
              </v-col>
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
              >
                <v-checkbox
                    v-model="free"
                    :label="`Free`"
                ></v-checkbox>
                <v-checkbox
                    v-model="paid"
                    :label="`Paid`"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="message" label="Enter message"></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="link" v-if="channel==='notification'" label="Enter link"></v-text-field>
              </v-col>
              <v-col
                  v-if="channel==='notification'"
                  cols="12"
                  md="3"
              >
                <v-text-field
                    v-model="option_1"
                    label="Option 1"
                />
              </v-col>
              <v-col
                  v-if="channel==='notification'"
                  cols="12"
                  md="3"
              >
                <v-text-field
                    v-model="option_2"
                    label="Option 2"
                />
              </v-col>
              <v-col
                  v-if="channel==='notification'"
                  cols="12"
                  md="3"
              >
                <v-text-field
                    v-model="option_3"
                    label="Option 3"
                />
              </v-col>
              <v-col
                  v-if="channel==='notification'"
                  cols="12"
                  md="6">
                <v-select
                    v-model="type"
                    :items="['course','notice','exam','user_guide','unit','unit_list','sms_alert','eligibility','corrected_questions','wrong_questions','favorite_questions','share','link']"
                    label="Choose type"
                />
              </v-col>
              <v-col
                  v-if="channel==='notification'"
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="content_id"
                    label="Content ID"
                />
              </v-col>
              <v-btn color="green" :disabled="!isValidForm" type="submit">Send Notification</v-btn>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="6">
          <h4>Send notification to a specific user</h4>
          <single-user-notification></single-user-notification>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import axios from "axios";
import SingleUserNotification from "@/components/admin/notification/SingleUserNotification";

export default {
  components: {SingleUserNotification},
  data() {
    return {
      selectedCourses: [],
      courses: [],
      all: false,
      message: '',
      link: '',
      free: '',
      paid: '',
      option_1: '',
      option_2: '',
      option_3: '',
      type: '',
      content_id: '',
      channel: 'notification',
    }
  },
  methods: {
    initialize() {
      const url = 'courses?filter=notification'
      axios.get(url).then((response) => {
        this.courses = response.data
      }).catch((error) => {
        console.log(error)
      })
    },
    sendNotification: function () {
      let link = '/admin/notifications';
      let data = {
        channel: this.channel,
        message: this.message,
        link: this.link,
        all: this.all ? "all" : "none",
        courses: this.selectedCourses,
        free: this.free,
        paid: this.paid,
        option_1: this.option_1,
        option_2: this.option_2,
        option_3: this.option_3,
        type: this.type,
        content_id: this.content_id,
      };
      axios.post(link, data).then(() => {
        this.resetForm();
      }).catch(() => {
        this.resetForm();
      })
    },
    resetForm: function () {
      this.channel = 'notification'
      this.message = ''
      this.link = ''
      this.selectedCourses = []
      this.free = false;
      this.paid = false;
      this.option_1 = ''
      this.option_2 = ''
      this.option_3 = ''
      this.type = ''
      this.content_id = ''
    }
  },
  computed: {
    isValidForm: function () {
      return this.message.trim().length > 0 && (this.selectedCourses.length > 0 || this.all);
    },
  },
  mounted() {
    this.initialize()
  }
}
</script>
