<template>
  <v-form @submit.prevent="sendNotification">
    <v-row>
      <v-col cols="12">
        <v-select
            v-model="channel"
            :items="[{name: 'Notification', value: 'notification'},{name: 'SMS', value: 'sms'}]"
            item-value="value"
            item-text="name"
            label="Choose channel"
        />
      </v-col>
      <v-col>
        <v-text-field label="Phone number" v-model="phone"></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea v-model="message" label="Enter message"></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-text-field v-model="link" v-if="channel === 'notification'" label="Enter link"></v-text-field>
      </v-col>
      <v-col
          v-if="channel === 'notification'"
          cols="12"
          md="3"
      >
        <v-text-field
            v-model="option_1"
            label="Option 1"
        />
      </v-col>
      <v-col
          v-if="channel === 'notification'"
          cols="12"
          md="3"
      >
        <v-text-field
            v-model="option_2"
            label="Option 2"
        />
      </v-col>
      <v-col
          v-if="channel === 'notification'"
          cols="12"
          md="3"
      >
        <v-text-field
            v-model="option_3"
            label="Option 3"
        />
      </v-col>
      <v-col
          v-if="channel === 'notification'"
          cols="12"
          md="6">
        <v-select
            v-model="type"
            :items="['course','notice','exam','user_guide','unit','unit_list','sms_alert','eligibility','corrected_questions','wrong_questions','favorite_questions','share','link']"
            label="Choose type"
        />
      </v-col>
      <v-col
          v-if="channel === 'notification'"
          cols="12"
          md="6"
      >
        <v-text-field
            v-model="content_id"
            label="Content ID"
        />
      </v-col>
      <v-btn color="green" :disabled="!isValidForm" type="submit">Send Notification</v-btn>
    </v-row>
  </v-form>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      phone: '',
      link: '',
      message: '',
      option_1: '',
      option_2: '',
      option_3: '',
      type: '',
      content_id: '',
      channel: 'notification',
    }
  },
  computed: {
    isValidForm: function () {
      return this.message.trim().length > 0 && !!this.phone;
    },
  },
  methods: {
    sendNotification: function () {
      let link = '/admin/notifications';
      let data = {
        channel: this.channel,
        message: this.message,
        link: this.link,
        phone: this.phone,
        option_1: this.option_1,
        option_2: this.option_2,
        option_3: this.option_3,
        type: this.type,
        content_id: this.content_id,
      };
      axios.post(link, data).then(() => {
        this.resetForm();
      }).catch(() => {
        this.resetForm();
      })
    },
    resetForm: function () {
      this.channel = 'notification'
      this.message = ''
      this.link = ''
      this.phone = ''
      this.option_1 = ''
      this.option_2 = ''
      this.option_3 = ''
      this.type = ''
      this.content_id = ''
    }
  }
}
</script>